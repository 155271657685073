import Experience from "../Experience.js";
import * as THREE from 'three'
import GUI from "lil-gui";
import { passThroughSymbol } from "next/dist/server/web/spec-compliant/fetch-event.js";
import { WireframeGeometry } from "three";


export default class Floor {

    constructor() {
        this.experience = new Experience()

        this.scene = this.experience.scene
        this.time = this.experience.time
        this.resources = this.experience.resources
        this.items = this.resources.items

        // GLB 
        this.roccia = this.items.roccia
        this.schermiSource = this.items.schermiVideo
        this.catene = this.items.catene
        this.erba = this.items.erba
        this.tubi = this.items.tubi
        this.schermi = this.items.schermi
        this.luci = this.items.luci // non addare alla scena solo ref 
        this.palle = this.items.palle // non addare alla scena solo ref 
        this.schermivideo = this.items.schermivideo // non addare alla scena solo ref 


        //Names
        this.roccia.scene.uuid = 'roccia'
        this.catene.scene.uuid = 'catene'
        this.erba.scene.uuid = 'erba'
        this.tubi.scene.uuid = 'tubi'
        this.schermi.scene.uuid = 'schermi'
        this.luci.scene.uuid = 'luci'
        this.palle.scene.uuid = 'palle'
        this.schermivideo.scene.uuid = 'schermivideo'




        this.elementi = [this.roccia, this.catene, this.erba, this.tubi, this.schermi]

        //this.gui = new GUI()

        // SetSleep
        this.sleep = time => {
            return new Promise((resolve) => setTimeout(resolve, time))
        }

        //setSpeed
        this.rocciaSpeed = 0

        this.setTextures()
        this.setMaterials()
        this.setModel()

        this.exploded = false



        let video = document.getElementById('video1')
        this.videoTexture = new THREE.VideoTexture(video)

        this.setSchermiFinti()
        this.setSchermiGeo()
        this.setSchermi()
        // setTimeout(() => {
        // this.steps()
        //  }, 6000);



    }


    setSchermiFinti() {
        this.schermiFinti = this.schermivideo.scene
        this.schermoLength = this.schermiFinti.children.length
        this.schermiFinti.children[0].name  = 'SCHERMO2'
        this.schermoDim = []
        for (let i = 1; i < this.schermoLength + 1; i++) {
            this.schermoDim.push(this.schermiFinti.children.find((child) => child.name === `SCHERMO${i}`))
        }
    }

    setSchermiGeo() {
        this.schermiGeo = []
        for (let i = 0; i < this.schermoLength; i++) {
            this.schermiGeo[i] = new THREE.PlaneGeometry(this.schermoDim[i].scale.x*2, this.schermoDim[i].scale.y*2)
            this.schermiGeo[i].name = `schermoGeo${i}`
        }
    }


    setSchermi() {
        this.videoTexture.minFilter = THREE.LinearFilter
        this.videoTexture.magFilter = THREE.LinearFilter

        let schermoMat = new THREE.MeshBasicMaterial({
            map: this.videoTexture,
            side: THREE.DoubleSide,
            flipY: false,
            toneMapped: false
        })

        this.schermo = []
        for (let i = 0; i < this.schermoLength; i++) {
            this.schermo[i] = new THREE.Mesh(this.schermiGeo[i], schermoMat)

            this.schermo[i].position.copy(this.schermoDim[i].position)
            this.schermo[i].rotation.copy(this.schermoDim[i].rotation)
            this.schermo[i].quaternion.copy(this.schermoDim[i].quaternion)
            this.schermo[i].rotateX(-Math.PI/2)
            this.schermo[i].name = `schermoMesh${i}`
            this.scene.add(this.schermo[i])
        }
        this.schermo[1].rotateY(Math.PI)

    }


    setMaterials() {
        this.material2 = new THREE.MeshMatcapMaterial({
            map: this.textures2.color,
            matcap: this.matCap.color,
            //bumpMap: this.bumpMap.color,
            displacementMap: this.displacementMap.color,
            displacementScale: 0,
            color: '#0xffffff',
            //bumpScale: 0.02,
            // emissive: '0xffffff',
            // emissiveIntensity:0.1,
            // specular: '#1c1c1c',
        })

        this.material3 = new THREE.MeshBasicMaterial({
            map: this.textures3.color,
            reflectivity: 0.4
        })

        this.cateneMat = new THREE.MeshPhysicalMaterial({
            thickness: 23.6,
            roughness: 0.173,
            clearcoat: 0.1,
            clearcoatRoughness: 0,
            transmission: 0.72,
            ior: 9.25,
            envMapIntensity: 25,
            color: 0x212121,
        });

        this.materialFoglie = new THREE.MeshPhongMaterial({


            color: new THREE.Color("rgb(0, 77, 9)"),
            transparent: true,
            opacity: 1
        })

        this.paliMat = new THREE.MeshPhongMaterial({
            color: 0x050505,
            specular: 0x061307,
            shininess: 288.6
        })

        //this.paliMaterial = this.gui.addFolder('PaliMaterial')
        // this.paliMaterial.addColor(this.paliMat, 'color')
        // this.paliMaterial.addColor(this.paliMat, 'specular')
        // this.paliMaterial.add(this.paliMat, 'shininess', 0, 300)

        // Adding .GUI
        //this.cateneMaterial = this.gui.addFolder('CateneMat')
        // this.cateneMaterial.add(this.cateneMat, 'thickness', 0, 400)
        // this.cateneMaterial.addColor(this.cateneMat, 'color')
        // this.cateneMaterial.add(this.cateneMat, 'roughness', 0, 1)
        // this.cateneMaterial.add(this.cateneMat, 'transmission', 0, 1)
        // this.cateneMaterial.add(this.cateneMat, 'ior', 0, 100)

        //this.rotation = this.gui.addFolder('rotation')


        //this.rockMaterial = this.gui.addFolder('RockMat')
        // this.rockMaterial.addColor(this.material2, 'color')
        // this.rockMaterial.add(this.material2, 'displacementScale', 0, 10000, 0.01)
        //this.rockMaterial.add(this.material2, 'bumpScale', 0, 1, 0.01)
        // this.rockMaterial.addColor(this.material2, 'emissive')
        // this.rockMaterial.addColor(this.material2, 'specular')
        // this.rockMaterial.add(this.material2, 'emissiveIntensity', 0, 1, 0.01)



    }

    setTextures() {

        this.textures2 = {}
        this.textures2.color = this.resources.items.rocktxt
        this.textures2.color.encoding = THREE.sRGBEncoding
        this.textures2.color.wrapS = THREE.RepeatWrapping
        this.textures2.color.wrapT = THREE.RepeatWrapping
        this.textures2.color.flipY = false

        this.textures3 = {}
        this.textures3.color = this.resources.items.schermitxt
        this.textures3.color.encoding = THREE.sRGBEncoding
        this.textures3.color.wrapS = THREE.RepeatWrapping
        this.textures3.color.wrapT = THREE.RepeatWrapping
        this.textures3.color.flipY = false

        this.displacementMap = {}
        this.displacementMap.color = this.resources.items.dispMap
        this.displacementMap.color.encoding = THREE.sRGBEncoding
        this.displacementMap.color.wrapS = THREE.RepeatWrapping
        this.displacementMap.color.wrapT = THREE.RepeatWrapping
        this.displacementMap.color.flipY = false


        this.bumpMap = {}
        this.bumpMap.color = this.resources.items.bumpMap
        this.bumpMap.color.encoding = THREE.sRGBEncoding
        this.bumpMap.color.wrapS = THREE.RepeatWrapping
        this.bumpMap.color.wrapT = THREE.RepeatWrapping
        this.bumpMap.color.flipY = false

        this.matCap = {}
        this.matCap.color = this.resources.items.matCap
        this.matCap.color.encoding = THREE.sRGBEncoding
        this.matCap.color.wrapS = THREE.RepeatWrapping
        this.matCap.color.wrapT = THREE.RepeatWrapping
        this.matCap.color.flipY = false


    }

    setModel() {

        this.rocciaModel = this.roccia.scene

        this.cateneModel = this.catene.scene
        this.erbaModel = this.erba.scene
        this.tubiModel = this.tubi.scene
        this.schermiModel = this.schermi.scene

        this.scene.add(this.rocciaModel, this.cateneModel, this.erbaModel, this.tubiModel, this.schermiModel)



        this.cateneModel.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = this.cateneMat
                child.position.set(child.position.x, child.position.y, child.position.z)
            }
        })


        this.rocciaModel.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = this.material2
                child.position.set(child.position.x, child.position.y, child.position.z)
            }
        })


        this.erbaModel.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = this.materialFoglie
                child.position.set(child.position.x, child.position.y, child.position.z)
            }
        })

        // //let coloreFoglie = this.gui.addFolder('ColoreFoglie')
        // coloreFoglie.addColor(this.materialFoglie, 'color')
        // coloreFoglie.add(this.materialFoglie, 'opacity', 0, 1, 0.1)


        this.tubiModel.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = this.paliMat
                child.position.set(child.position.x, child.position.y, child.position.z)
            }
        })


        this.schermiModel.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = this.material3
                child.position.set(child.position.x, child.position.y, child.position.z)
            }
        })

        // this.mixer = new THREE.AnimationMixer(this.model)
        // this.oggetti[2].traverse((child) => {
        //     if(child instanceof THREE.Mesh){
        //         child.material = new THREE.MeshBasicMaterial({color: 0x179740})
        //     }
        // })
    }

    firstAnimation() {
        const animate = async () => {
            for (let i = 1; i <= 300; i++) {
                await this.sleep(1)
                this.material2.displacementScale = i * 100
            }
            this.avviaDeanimate = true
            this.materialFoglie.opacity = 0
            this.removeAnimation()
        }
        if (this.avviaSecondoStep == true) {
            this.rocciaSpeed = 0
            this.rocciaModel.quaternion.w = 1
            this.rocciaModel.quaternion.x = 0
            this.rocciaModel.quaternion.y = 0
            this.rocciaModel.quaternion.z = 0

            animate()
        }
    }

    removeAnimation() {
        const deanimate = async () => {
            for (let i = 1; i <= 300; i++) {
                await this.sleep(10)
                this.material2.displacementScale = 30000 - i * 100
            }
            this.startErba = true
            this.erbaBack()
        }
        if (this.avviaDeanimate == true) {
            deanimate()
        }
    }

    erbaBack() {
        this.erbaModel.visible = true
        const transitionErba = async () => {
            for (let i = 1; i < 100; i++) {
                await this.sleep(50)
                this.materialFoglie.opacity = 0 + i / 100

            }
            this.exploded = false

        }
        if (this.startErba == true) {
            transitionErba()
        }
    }

    erbaOut() {
        const transitionBackErba = async () => {
            for (let i = 1; i < 100; i++) {
                await this.sleep(25)
                this.materialFoglie.opacity = 1 - i / 100

            }
            this.firstStep = true
            this.rotationSpeed()
        }
        transitionBackErba()

    }

    rotationSpeed() {
        const rotateGradient = async () => {
            for (let i = 1; i < 50; i++) {
                await this.sleep(50)
                this.rocciaSpeed += 0.01 * Math.pow(1.1, i)
            }
            this.avviaSecondoStep = true
            this.firstAnimation()
        }
        if (this.firstStep == true) {
            rotateGradient()
        }
    }

    steps() {
        this.erbaOut()
        //this.exploded = false
    }

    update() {
        this.rocciaModel.rotation.y += this.rocciaSpeed
    }
}


