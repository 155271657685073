import * as THREE from 'three'
import Experience from './Experience'

export default class Renderer {
    constructor() 
    {
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.setInstance()
        
    }

    setInstance() 
    {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true, // imporve performances,
            powerPreference: 'high-performance',
            precision: 'highp'

        })
        this.instance.physicallyCorrectLights = true
        this.instance.outputEncoding = THREE.sRGBEncoding
        this.instance.toneMapping = THREE.ReinhardToneMapping
        this.instance.toneMappingExposure = 1
        this.instance.shadowMap.enabled = true
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap
        this.instance.setClearColor('#211d20')
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
        this.instance.autoClear = true
    }

    resize() 
    {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
    }

    update() 
    {
        this.instance.render(this.scene, this.camera.instance)
        //console.log("Number of Triangles :", this.instance.info.render.triangles); // quite useful to understand the meshes
    }
}