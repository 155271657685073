export default [

    {
        name: 'roccia',
        type: 'GLTF',
        path: 'models/meshes/roccia.glb'
    },

    {
        name: 'catene',
        type: 'GLTF',
        path: 'models/meshes/catene.glb'
    },

    {
        name: 'erba',
        type: 'GLTF',
        path: 'models/meshes/erba.glb'
    },

    {
        name: 'luci',
        type: 'GLTF',
        path: 'models/meshes/luci.glb'
    },

    {
        name: 'palle',
        type: 'GLTF',
        path: 'models/meshes/palle.glb'
    },

    {
        name: 'schermi',
        type: 'GLTF',
        path: 'models/meshes/schermi.glb'
    },

    {
        name: 'schermivideo',
        type: 'GLTF',
        path: 'models/meshes/schermivideo.glb'
    },

    {
        name: 'tubi',
        type: 'GLTF',
        path: 'models/meshes/tubi.glb'
    },

    {
        name: 'catenetxt',
        type: 'texture',
        path: 'models/textures/CATENE.jpg'
    },

    {
        name: 'rocktxt',
        type: 'texture',
        path: 'models/textures/ROCK.jpg'
    },

    {
        name: 'schermitxt',
        type: 'texture',
        path: 'models/textures/SCHERMI.jpg'
    },

    {
        name: 'tubitxt',
        type: 'texture',
        path: 'models/textures/TUBI.jpg'
    },
    {
        name: 'dispMap',
        type: 'texture',
        path: 'models/textures/displacementMap.jpeg'
    },
    {
        name: 'bumpMap',
        type: 'texture',
        path: 'models/textures/bumpMap.jpeg'
    },
    {
        name: 'matCap',
        type: 'texture',
        path: 'models/textures/matCap.jpg'
    },
    {
        name: 'schermiVideo',
        type: 'GLTF',
        path: 'models/meshes/schermivideo.glb'
    },
]