let initialPosition = {x: 4.8111893903950955, y: 4.0503389178098694, z: -0.15793443184945344}
let baseTarget = {x: 0, y: 0, z:0} // posizione della roccia
let basePosition = {x: -0.18919578022556185, y: 0.1712131453029124, z: 2.3401260199135745}
let verticalBasePosition =  {x: -0.22975378743262442, y: 0.24426433663419897, z: 3.2513735437517184}



let p = [

// {
//     name: 'iphone1',
//     position: {x: -0.5153176003924129, y: 0.47480210807144513, z: 9.474123216894359},
//     settings: 'to_do',
//     target: baseTarget
// },

// {
//     name: 'iphone2',
//     position: {x: -5.704805825615213, y: 0, z: -2.600348370561921},
//     settings: 'to_do',
//     target: {x: 0, y: 0.0, z:0}
// },

{
    name: 'base',
    position: basePosition,
    settings: {rotate: true, zoom: false},
    target: baseTarget,
    vertical: verticalBasePosition
}

]


export{p, basePosition, initialPosition}


//Vector3 {x: 26.536941465548278, y: 6.530498380852657, z: -4.020294809406103}