import Experience from './Experience'
import {Howl, Howler} from 'howler'

import soundtrack from '../../static/Audio/soundtrack.mp3'
import click from '../../static/Audio/click.mp3'
  
export default class Sounds
{
    constructor()
    {
        this.experience = new Experience()

        this.soundtrack = new Howl({
            src: [soundtrack],
            loop: true,
            volume: 0.5
        })

        this.click= new Howl({
            src: [click],
            volume: 0.3
        })


        this.setMute()
    }

    setMute()
    {

        //SET UP

        this.muted = typeof this.debug !== 'undefined'
        Howler.mute(this.muted)

        //M KEY

        window.addEventListener('keydown', (event) => {
            if(event.key === 'm')
            {
                this.muted = !this.muted
                Howler.mute(this.muted)
            }
        })

        //DOCUMENTO NASCOSTO

        document.addEventListener('visibilitychange', () =>
        {
            if(document.hidden)
            {
                Howler.mute(true)
            }
            else
            {
                Howler.mute(this.muted)
            }
        })

         //DEBUG

         if(this.debug)
         {
             this.debugFolder.add(this, 'muted').listen().onChange(() =>
             {
                 Howler.mute(this.muted)
             })
         }
    }

    playSoundtrack()
    {
        this.soundtrack.play()
    }

    playClick() {
        this.click.play()
    }
}