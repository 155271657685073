import Experience from "../Experience";
import Environment from './Environment';
import Floor from "./Floor";
import { Color } from "three";

export default class World {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.resources.on('ready', () => {
            //Setup 
            this.floor = new Floor()
            this.environment = new Environment()
            this.scene.background = new Color('#b5b5b7')
        })
    }

    update()
    {
        this.floor.update()
    }
}