import * as THREE from 'three'
import Experience from "../Experience";

export default class Environment 
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene 
        this.resources = this.experience.resources
        this.gui = this.experience.world.floor.gui   //<---        

        this.setSunLight()
        //this.addGui()   // <---
    }

    addGui(){


        let luceAmbiente = this.gui.addFolder('LuceAmbiente')
        luceAmbiente.addColor(this.sunLight, 'color')
        luceAmbiente.add(this.sunLight, 'intensity', 0, 1000)
        luceAmbiente.add(this.sunLight, 'distance', 0, 1)
        luceAmbiente.add(this.sunLight, 'decay', 0, 1)
        luceAmbiente.add(this.sunLight.position, 'x', -1000, 1000)
        luceAmbiente.add(this.sunLight.position, 'y', -1000, 1000)
        luceAmbiente.add(this.sunLight.position, 'z', -1000, 1000)

        let luceIntorno1 = this.gui.addFolder('luceIntorno1')
        luceIntorno1.addColor(this.sunLight3, 'color')
        luceIntorno1.add(this.sunLight3, 'intensity', 0, 1000)
        luceIntorno1.add(this.sunLight3, 'distance', 0, 100)
        luceIntorno1.add(this.sunLight3, 'angle', 0, 360)
        luceIntorno1.add(this.sunLight3, 'penumbra', 0, 5)
        luceIntorno1.add(this.sunLight3, 'decay', 0, 10)
        luceIntorno1.add(this.sunLight3.position, 'x', -1000, 1000)
        luceIntorno1.add(this.sunLight3.position, 'y', -1000, 1000)
        luceIntorno1.add(this.sunLight3.position, 'z', -1000, 1000)

        let luceIntorno2 = this.gui.addFolder('luceIntorno2')
        luceIntorno2.addColor(this.sunLight4, 'color')
        luceIntorno2.add(this.sunLight4, 'intensity', 0, 1000)
        luceIntorno2.add(this.sunLight4, 'distance', 0, 100)
        luceIntorno2.add(this.sunLight4, 'angle', 0, 360)
        luceIntorno2.add(this.sunLight4, 'penumbra', 0, 5)
        luceIntorno2.add(this.sunLight4, 'decay', 0, 10)
        luceIntorno2.add(this.sunLight4.position, 'x', -1000, 1000)
        luceIntorno2.add(this.sunLight4.position, 'y', -1000, 1000)
        luceIntorno2.add(this.sunLight4.position, 'z', -1000, 1000)

        let luceIntorno3 = this.gui.addFolder('luceIntorno3')
        luceIntorno3.addColor(this.sunLight5, 'color')
        luceIntorno3.add(this.sunLight5, 'intensity', 0, 1000)
        luceIntorno3.add(this.sunLight5, 'distance', 0, 100)
        luceIntorno3.add(this.sunLight5, 'angle', 0, 360)
        luceIntorno3.add(this.sunLight5, 'penumbra', 0, 5)
        luceIntorno3.add(this.sunLight5, 'decay', 0, 10)
        luceIntorno3.add(this.sunLight5.position, 'x', -1000, 1000)
        luceIntorno3.add(this.sunLight5.position, 'y', -1000, 1000)
        luceIntorno3.add(this.sunLight5.position, 'z', -1000, 1000)

        let luceIntorno4 = this.gui.addFolder('luceIntorno4')
        luceIntorno4.addColor(this.sunLight6, 'color')        
        luceIntorno4.add(this.sunLight6, 'intensity', 0, 1000)
        luceIntorno4.add(this.sunLight6, 'distance', 0, 100)
        luceIntorno4.add(this.sunLight6, 'angle', 0, 360)
        luceIntorno4.add(this.sunLight6, 'penumbra', 0, 5)
        luceIntorno4.add(this.sunLight6, 'decay', 0, 10)
        luceIntorno4.add(this.sunLight6.position, 'x', -1000, 1000)
        luceIntorno4.add(this.sunLight6.position, 'y', -1000, 1000)
        luceIntorno4.add(this.sunLight6.position, 'z', -1000, 1000)


    }

    setSunLight()
    {
        this.sunLight = new THREE.PointLight('#ffffff', 10, 100)
        
        this.sunLight.shadow.camera.far = 100
        this.sunLight.shadow.mapSize.set(512, 512)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(0, 30, 0)
        this.sunLight.castShadow = true
        this.scene.add(this.sunLight)
        this.helper = new THREE.CameraHelper( this.sunLight.shadow.camera )
        this.lightHelper = new THREE.PointLightHelper( this.sunLight, 10 );


        //this.scene.add(this.helper)
        // this.scene.add(this.lightHelper)


        const distance = 80 
        const angle = 0.8
        const penumbra = 0.5
        const decay = 1.2
        this.sunLight3 = new THREE.SpotLight('#FFFfff', 20, distance, angle, penumbra, decay)
        this.sunLight3.position.set(20, 8, 0)
        this.sunLight3.castShadow = true
        this.scene.add(this.sunLight3)
        this.lightHelper3 = new THREE.SpotLightHelper( this.sunLight3, 10 );
        //this.scene.add(this.lightHelper3)

        this.sunLight4 = new THREE.SpotLight('#FFFfff', 20, distance, angle, penumbra, decay)
        this.sunLight4.position.set(-20, 8, 0)
        this.sunLight4.castShadow = true
        this.scene.add(this.sunLight4)
        this.lightHelper4 = new THREE.SpotLightHelper( this.sunLight4, 10 );
        //this.scene.add(this.lightHelper4)

        this.sunLight5 = new THREE.SpotLight('#FFFfff', 20, distance, angle, penumbra, decay)
        this.sunLight5.position.set(0, 8, 20)
        this.sunLight5.castShadow = true
        this.scene.add(this.sunLight5)
        this.lightHelper5 = new THREE.SpotLightHelper( this.sunLight5, 10 );
        //this.scene.add(this.lightHelper5)

        this.sunLight6 = new THREE.SpotLight('#FFFfff', 20, distance, angle, penumbra, decay)
        this.sunLight6.position.set(0, 8, -20)
        this.sunLight6.castShadow = true
        this.scene.add(this.sunLight6)
        this.lightHelper6 = new THREE.SpotLightHelper( this.sunLight6, 10 );
        //this.scene.add(this.lightHelper6)
    }    
}