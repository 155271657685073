import Experience from "./Experience.js";
import * as THREE from 'three'
import { gsap } from 'gsap'
import EventEmitter from "./Utils/EventEmitter.js";
import { passThroughSymbol } from "next/dist/server/web/spec-compliant/fetch-event.js";

export default class LoadingFloor extends EventEmitter {
    constructor() {
        super()

        //SetUp
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sounds = this.experience.sounds
        this.resources = this.experience.resources
        this.sizes = this.experience.sizes
        this.overlay = document.querySelector('.overlay')
        this.cooking = document.querySelector('#cooking')
        this.startButton = document.querySelector('.start')
        this.linkedButtons = document.getElementById('link-buttons')
        this.backDoorLogo = document.getElementById('backdoor')
        this.video = document.getElementById('video1')

        // Progress
        this.resources.on('itemLoaded', () => {
            this.progressRatio = (this.resources.loaded + 1) / this.resources.toLoad

            document.getElementById("progressPercentage").innerHTML = Math.trunc(this.progressRatio * 100)
        })

        //Loaded
        this.resources.on('ready', () => {

            window.setTimeout(() => {
                this.cooking.classList.add('fade')
            }, 1500)

            window.setTimeout(() => {
                this.readyScreen()
            }, 2500)
        })
    }

    readyScreen() {
        this.startButton.style.display = "inline"
        this.startButton.classList.add('fadeIn')
        this.startButton.addEventListener("click", async () => {

            // Remove overlay and button
            this.overlay.classList.add('fade')
            this.startButton.classList.add('fadeOut')
            this.linkedButtons.style.position = "absolute";
            this.backDoorLogo.remove();
                       

            window.setTimeout(() => {
                this.startButton.remove()
                this.overlay.remove()                
            }, 2000)

            // Trigger start events
            // this.controller = this.experience.controller
            // //this.performance = this.experience.performance

            // // Move Camera
            // this.controller.camControls.toDefault()

            // Play Sounds
            this.sounds.playClick()
            this.sounds.playSoundtrack()

            // Required for instagram brower compatibility
            this.sizes.resize()

            // initial interpolation
            this.experience.camera.initial_lerp()
            this.video.play()

            // Wait before performance Check
            //window.experience.raycaster.setRaycaster()
            // await this.sleep(500)
            // this.performance.performanceCheck()



            // Emit Event
            this.trigger('start')
            await this.sleep(500)

            // Play Sounds

        }, { once: true });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}