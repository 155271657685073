import * as THREE from 'three'
import EventEmitter from './EventEmitter.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Experience from '../Experience.js'
import { Color } from "three";

const DRACO = 'draco'
const GLTF = 'GLTF'
const TEXTURE = 'texture'
const SKYBOX = 'skybox'


export default class Resources extends EventEmitter
{
    constructor(sources)
    {
        super()

        this.experience = new Experience()
        this.sources = sources
        this.renderer = this.experience.renderer.instance  
        this.camera = this.experience.camera.instance

        //Setup 
        this.items = {}
        this.toLoad = this.sources.length
        this.loaded = 0
        this.setLoaders()
        this.startLoading()

    }

    /*DRACO KEY nel dictionary buggato, sistemare*/
    setLoaders()
    {
        this.loaders = {} 
        this.easyLoader = new GLTFLoader()
        this.dracoLoader = new DRACOLoader()

        //this.loaders[DRACO] = new DRACOLoader(r)
        //this.loaders[GLTF] = new GLTFLoader(r)
        //this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath( '/draco/')
        //this.loaders[GLTF].setDRACOLoader(this.dracoLoader)
        this.easyLoader.setDRACOLoader(this.dracoLoader)
        this.loaders[GLTF] = this.easyLoader
        //this.loaders[DRACO] = this.loaders[GLTF]
        this.loaders[TEXTURE] = new THREE.TextureLoader()
        this.loaders[SKYBOX] =  new THREE.CubeTextureLoader()
    }
    
    startLoading()
    {
        //Load le sources 
        let canv = document.getElementById('webgl')
        for ( const source of this.sources)
        {
                this.loadCorrectly(source)
                canv.width = window.innerWidth
                canv.height = window.innerWidth
                this.antibug()
                this.experience.scene.background = new Color('#b5b5b7')
        }

    }
    antibug() {
        let canv = document.getElementById('webgl')
        var width =canv.width; //declare width
        
        var height = canv.height; //declare height
        console.log(width, height)
    
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer.clear();
        
        this.renderer.setSize( width, height );
        this.experience.camera.update();
    }


    sourceLoaded(source, file)
    {
        this.trigger('itemLoaded')

        this.items[source.name] = file
        this.loaded++

        if(this.loaded === this.toLoad)
        {
            this.trigger('ready')
        }
        

    }

    loadCorrectly(source)
    { 
        this.loaders[source.type].load(
            source.path,
            (file) =>
            {
                this.sourceLoaded(source, file)
                
            }
        )
    }

}