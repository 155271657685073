import gsap from 'gsap'
import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import {p, basePosition, initialPosition} from './Movements/Config.js'
import { defaultSettings } from './orbitSettings.js'

export default class Camera{

    constructor()
    {
        // recall experience properties needed
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

       // set camera and orbitcontrols 
        this.setInstance()
        this.setControls()

        // set transition functions of the camera
        let position_info = p // load obj in another variable
        this.setTransitions(position_info)
        

        // INITIAL Interpolation: initialPosition --> basePosition
        this.done = false
    }

    initial_lerp()
    {   
        /* function that manages the initial interpolation, 
        it has to use "window" instead of this because the EventListener
        lose the context */
        window.experience.camera.transitions['base'](3.5)
    }

    setInstance()
    {   
        /* Initial setting of the PerpesctiveCamera */

        this.instance = new THREE.PerspectiveCamera(30, this.sizes.width / this.sizes.height, 0.1, 100)
        
        this.instance.position.x = initialPosition.x
        this.instance.position.z = initialPosition.z
        this.instance.position.y = initialPosition.y

        this.scene.add(this.instance)
    }

    setControls(){
        /* Initial setting of the OrbitControls */
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
        this.controls.enablePan = false
        this.controls.rotateSpeed = 1.2
        this.controls.zoomSpeed = 0.8
        this.controls.enableRotate = true
        this.controls.enableZoom = true
        this.controls.maxAzimuthAngle = defaultSettings.max_azimut_angle
        this.controls.minAzimuthAngle = defaultSettings.min_azimut_angle
        this.controls.minPolarAngle = defaultSettings.min_pol_angle 
        this.controls.maxPolarAngle = defaultSettings.max_pol_angle
    }

    setTransitions(positions){
        /* this Function set the transtions given an arry of objects*/
        this.transitions = {}

        for(let i = 0; i < positions.length; i++)
        {
            let name = positions[i].name

            this.transitions[name] = async (duration) =>
            {
                this.controls.enableRotate = false
                this.controls.enableZoom = false
                // to fix
                if(name == 'base' && this.experience.config.vertical){
                    gsap.to(this.instance.position, { duration: duration, ease: "power1.inOut",
                x: positions[i].vertical.x,
                y: positions[i].vertical.y,
                z: positions[i].vertical.z})
                }
                else {
                gsap.to(this.instance.position, { duration: duration, ease: "power1.inOut",
                x: positions[i].position.x,
                y: positions[i].position.y,
                z: positions[i].position.z})
                }
                gsap.to(this.controls.target, { duration: duration, ease: "power1.inOut",
                    x: positions[i].target.x,
                    y: positions[i].target.y,
                    z: positions[i].target.z})
    
                await this.sleep(1500)
                this.controls.enableRotate = positions[i].settings.rotate
                this.controls.enableZoom = positions[i].settings.zoom
            } 
        }
    }

    sleep(ms) 
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix() 
    }

    update()
    {      
        this.controls.update()
        // if(this.experience.time.elapsed > 10000 && !this.done){
        //     this.transitions.iphone2(1.5)
        //     console.log('ci vado')
        //     this.done = true
        // }
    }
}