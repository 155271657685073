import Experience from './Experience.js'
import * as THREE from 'three'


export default class Raycaster
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera.instance   
        
        
        
        this.setRaycaster()
        
        this.loadResources()

       
    }

    
    loadResources() {
        this.resources = this.experience.resources
        this.resources.on('ready', () => {
            this.floor = this.experience.world.floor
            this.resource = this.floor.resource
            this.model = this.floor.model
            this.roccia = this.floor.roccia

            this.objectsToClick = []
            this.hitboxes = {}
            this.setRocciaHitBox()
        })
    }

    setRocciaHitBox()
    {
        this.hitBoxMaterial = new THREE.MeshBasicMaterial( { color: 0xff0000, wireframe: true} )
        // this.hitbox = new THREE.Box3().setFromObject(this.roccia.scene)
        // console.log(this.hitbox)
        // let x = Math.abs(this.hitbox.max.x-this.hitbox.min.x)
        // let y = Math.abs(this.hitbox.max.y-this.hitbox.min.y)
        // let z = Math.abs(this.hitbox.max.z-this.hitbox.min.z)
        // console.log(x, y, z)

        // let help = new THREE.Box3Helper(this.hitbox,'0xffff00')
        // this.scene.add(help)

        this.hitboxes['roccia'] = new THREE.Mesh(
            new THREE.BoxGeometry(0.4,0.8,0.4),
            this.hitBoxMaterial
        )
        let pos = this.roccia.scene.position
        this.hitboxes['roccia'].position.copy(pos)
        this.hitboxes['roccia'].name = 'roccia'
        this.scene.add(this.hitboxes['roccia'])
        this.hitboxes['roccia'].visible = false
        this.objectsToClick.push(this.hitboxes['roccia'])
    }

    setRaycaster() {
        this.rayCaster = new THREE.Raycaster()
        this.touchedPoints = []
        this.cursor = new THREE.Vector2()


        window.addEventListener('pointerdown', (event) =>
        {
            this.touchedPoints.push(event.pointerId)
            //console.log(this.touchedPoints)
            this.cursorXMin = Math.abs((event.clientX / this.sizes.width * 2 - 1)*0.9)
            this.cursorXMax = Math.abs((event.clientX / this.sizes.width * 2 - 1)*1.1)

            this.cursorYMin = Math.abs((event.clientY / this.sizes.height * 2 - 1)*0.9)
            this.cursorYMax = Math.abs((event.clientY / this.sizes.height * 2 - 1)*1.1)

        })

        window.addEventListener('pointerup', (event) =>
            {      

                this.cursor.x = event.clientX / this.sizes.width * 2 - 1
                this.cursor.y = - (event.clientY / this.sizes.height) * 2 + 1

                this.absX = Math.abs(this.cursor.x)
                this.absY = Math.abs(this.cursor.y)

                //console.log(this.touchedPoints.length)
                if(this.touchedPoints.length > 1 && 
                this.absX > this.cursorXMin && this.absX < this.cursorXMax &&
                this.absY > this.cursorYMin && this.absY < this.cursorYMax) 

                {
                console.log('cliccatasosa')
                this.click(this.cursor)

                this.touchedPoints = []
                }
                else
                {this.touchedPoints = []}
            })
    
    }
    click(cursor) {

        this.rayCaster.setFromCamera(cursor, this.camera)

        this.intersects = this.rayCaster.intersectObjects(this.objectsToClick)
        
        if (this.intersects.length) {
            let clickedOBJ = this.intersects[0].object
            if(clickedOBJ.name == 'roccia' && !this.floor.exploded)
            {   

                //funzione esplosione roccia
            //console.log('faccio esplodere')
            this.floor.exploded = true 
            //this.floor.steps()  
                
          
         }       

    }
}




    update()
    {
        this.rayCaster.setFromCamera(this.mouse, this.camera)
        this.rayOrigin = this.rayCaster.ray.origin
        this.rayDirection = this.rayCaster.ray.direction
    }
}