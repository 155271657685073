import * as THREE from 'three'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import Resources from './Utils/Resources.js'
import Sizes from "./Utils/Sizes.js"
import Time from "./Utils/Time.js"
import World from './World/World.js'
import sources from './sources.js'
import LoadingFloor from './LoadingFloor.js'
import Raycaster from './Raycaster.js'
import Sounds from './Sounds.js'


// INSTANCE

let instance = null

export default class Experience {
    constructor(canvas) {
        //singleton
        if (instance) {
            return instance
        }
        instance = this

        // Global Access
        window.experience = this
        this.canvas = canvas

        //config PER PHONE COME JESSE
        this.config = {}
        this.config.touch = false
        window.addEventListener('touchstart', () => {
            this.config.touch = true
        }, { once: true })
  
        this.scene = new THREE.Scene()
        this.sizes = new Sizes()

        // config vertical
        if(this.sizes.width / this.sizes.height > 1) {this.config.vertical = false}
        else {this.config.vertical = true}
        console.log('sei verticale:', this.config.vertical)

        //Iphone View CODICE JESSE 
        if(this.sizes.width / this.sizes.height > 1) {this.config.vertical = false}
        else {this.config.vertical = true}

        //Setup IMPORT NELL'ORDINE DI JESSE

        this.time = new Time()
        this.camera = new Camera()
        this.renderer = new Renderer()
        this.sounds = new Sounds()
        this.resources = new Resources(sources)

        


        this.loadingFloor = new LoadingFloor()
        //Movement
        this.world = new World()
        this.raycaster = new Raycaster()
        function sleep(ms) 
        {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        const startButton = document.querySelector('.start')
        startButton.addEventListener('click', activate_rayCaster)
        function activate_rayCaster(){
            //console.log('attivo_ray')
      
            window.experience.raycaster.setRaycaster()
        }

        //Time
        this.time.on('tick', () => {
            this.update()
        })

        //Resize
        this.sizes.on('resize', () => {
            this.resize()
        })
    }

    resize() {
        this.camera.resize()
        this.renderer.resize()
    }

    
    update() {
        this.camera.update()
        this.renderer.update()
        if(this.world.floor)
        {
            this.world.update()
        }
    }
}