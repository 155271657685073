import EventEmitter from "./EventEmitter";

export default class Time extends EventEmitter
{
    constructor()
    {
        super()
        
        // Setup

        this.start = Date.now()
        this.current = this.start 
        this.elapsed = 0
        this.delta = 16


        window.requestAnimationFrame(()=>
        {
            this.tick()
        })
    }

    tick()
    {   
        const currentTime = Date.now() // def curent
        this.delta = currentTime- this.current // tolgo il current che in realtà è t-1
        this.current = currentTime //rimetto t-1 come t
        this.elapsed = this.current - this.start


        this.trigger('tick')
        window.requestAnimationFrame(()=>
        {
            this.tick()
        })


      
    }
}