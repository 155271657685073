let cameraposition = {x:9.5, y:5, z:0}
let defaultSettings = 
{
    min_distance:cameraposition.x,
    max_distance: cameraposition.x,
    max_pol_angle: Math.PI / 2.1,
    min_pol_angle: Math.PI / 2.1,
    min_azimut_angle: Infinity,
    max_azimut_angle: Infinity,
    state: 'def'
}

let cameraSettings = 
{
    position: cameraposition,
    start: [cameraposition.x, cameraposition.z],
    height: cameraposition.y
}

export {defaultSettings,cameraSettings}
